import React, {Component} from "react";
import PropTypes from "prop-types";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";

class ProgressBarView extends Component {

    getProgressWidth = (current, total) => {
        current = parseFloat(current);
        total = parseFloat(total);
        if (total === 0) return [0, 100];
        let progressWidth = Math.min(100, (current / total) * 100);
        return `${progressWidth}%`;
    }

    render() {
        const {currentCount, totalCount} = this.props;
        let progressWidth = this.getProgressWidth(currentCount, totalCount);

        return <Row backgroundColor={this.props.secondaryColor || "secondary"}
                    spacing="none" width="100%" widths={["grid"]}>
            <Column backgroundColor={this.props.primaryColor || "primary"} width={progressWidth}>
                <Text type={"b100"} alignment={"right"}>&nbsp;</Text>
            </Column>
            <div style={{position: "absolute", right: 0}}>
                <Text type={"b100"} alignment={"right"}>&nbsp;{currentCount}/{totalCount}&nbsp;</Text>
            </div>
        </Row>;
    }
}

ProgressBarView.propTypes = {
    currentCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string
}

export default ProgressBarView;