import Analytics from "@aws-amplify/analytics";
import Auth from "@aws-amplify/auth";
import {dolphinEventsBuilder, dolphinMetricsBuilder} from "../model/DolphinAnalyticEvents";
import AnalyticEventKeys from "./AnalyticEventKeys";
import {getDolphinAttributes} from "./ModuleAnalyticsUtility";
import Logger from "../utils/Logger";
import {wrapWithTimeout} from "../utils/CommonUtils"
import Constants from "../constants/Constants";
import PinpointConstants from "../constants/PinpointConstants";

function getPinpointConstants(region){
    let cognitoIdentityPoolId;
    let pinpointAppId;
    let pinpointRegion;
    switch (region) {
        case Constants.Region.REGION_NA:
            cognitoIdentityPoolId = PinpointConstants.US_EAST_1.COGNITO_IDENTITY_POOL_ID;
            pinpointAppId = PinpointConstants.US_EAST_1.PINPOINT_APP_ID;
            pinpointRegion = PinpointConstants.US_EAST_1.PINPOINT_REGION;
            break;
        case Constants.Region.REGION_FE:
            cognitoIdentityPoolId = PinpointConstants.US_WEST_2.COGNITO_IDENTITY_POOL_ID;
            pinpointAppId = PinpointConstants.US_WEST_2.PINPOINT_APP_ID;
            pinpointRegion = PinpointConstants.US_WEST_2.PINPOINT_REGION;
            break
        case Constants.Region.REGION_EU:
            cognitoIdentityPoolId = PinpointConstants.EU_WEST_1.COGNITO_IDENTITY_POOL_ID;
            pinpointAppId = PinpointConstants.EU_WEST_1.PINPOINT_APP_ID;
            pinpointRegion = PinpointConstants.EU_WEST_1.PINPOINT_REGION;
            break;
        default:
            cognitoIdentityPoolId = PinpointConstants.US_EAST_1.COGNITO_IDENTITY_POOL_ID;
            pinpointAppId = PinpointConstants.US_EAST_1.PINPOINT_APP_ID;
            pinpointRegion = PinpointConstants.US_EAST_1.PINPOINT_REGION;
    }
    return {
        cognitoIdentityPoolId: cognitoIdentityPoolId,
        pinpointAppId: pinpointAppId,
        pinpointRegion: pinpointRegion
    }
}

export default {
    region: "",
    async initializeAnalytics(region){
        this.region = region;
        // Getting pinpoint constants according to region
        const pinpointConstants = getPinpointConstants(region);
        const cognitoIdentityPoolId = pinpointConstants.cognitoIdentityPoolId;
        const pinpointAppId = pinpointConstants.pinpointAppId;
        const pinpointRegion = pinpointConstants.pinpointRegion;

        const authConfig = {
            identityPoolId: cognitoIdentityPoolId,
            region: pinpointRegion
        };

        const pinpointConfig = {
            autoSessionRecord: false,
            AWSPinpoint: {
                // Amazon Pinpoint App Client ID
                appId: pinpointAppId,
                // Amazon service region
                region: pinpointRegion,
                mandatorySignIn: false,
                bufferSize: 100,
                resendLimit: 1
            }
        };
        Auth.configure(authConfig);
        Analytics.configure(pinpointConfig);
    },
    executeAPIAnalytics(module, startTime, failure) {

        const dolphinAttributes = getDolphinAttributes(this.region);
        dolphinAttributes.setModule(module)
            .setDescription(AnalyticEventKeys.Modules.PWA)
            .build();

        const dolphinMetrics = new dolphinMetricsBuilder()
            .setDuration(Date.now() - startTime)
        if (failure === true) {
            dolphinMetrics.setFailures()
                .build();
        }

        const dolphinAnalytics = new dolphinEventsBuilder()
            .setName(module)
            .setAttributes(dolphinAttributes)
            .setMetrics(dolphinMetrics)
            .build();
        Analytics.record(dolphinAnalytics);
    },
    processAnalytics(startTime, description, response, module, scanContent, description1,
                     eventName, failure, routeCode, immediate) {

        const dolphinAttributes = getDolphinAttributes(this.region);
        dolphinAttributes.setModule(module)
            .setDescription(description)
            .setScanContent(scanContent)
            .setDescription1(description1)

        if (routeCode) {
            dolphinAttributes.setRouteCode(routeCode)
        }

        dolphinAttributes.build();

        const dolphinMetrics = new dolphinMetricsBuilder()
            .setDuration(Date.now() - startTime)

        if (failure === true) {
            dolphinMetrics.setFailures()
                .build();
        }

        const dolphinAnalytics = new dolphinEventsBuilder()
            .setName(eventName)
            .setAttributes(dolphinAttributes)
            .setMetrics(dolphinMetrics)
            .setImmediate(immediate)
            .build();

        return Analytics.record(dolphinAnalytics);
    },

    /**
     * This waits for pinpoint API to publish analytic till "publishTimeout" milliseconds.
     * This helps in adding await on this async method with timeout.
     * Note: The original pinpoint promise will not be terminated even after timeout
     * Note: The immediate flag will cause the event to emit immediately and hence it will not be sent into buffer,
     * due to this event will be lost if API call fails with non-retryable error, which otherwise would have retried.
     *
     * @param startTime
     * @param description
     * @param response
     * @param module
     * @param scanContent
     * @param description1
     * @param eventName
     * @param failure
     * @param routeCode
     * @param publishTimeout
     * @return {Promise<void>}
     */
    async processAnalyticsWithTimeout(startTime, description, response, module, scanContent, description1,
                                      eventName, failure, routeCode, publishTimeout) {
        let publishPromise = this.processAnalytics(startTime, description, response, module, scanContent, description1,
            eventName, failure, routeCode, true);

        try {
            await wrapWithTimeout(publishPromise, publishTimeout);
        } catch (e) {
            Logger.log.error(`Error while publishing event ${eventName}: ${e}`);
        }
    }
}
