// Export Interceptors
import Logger from "./Logger";

export const nativeInteractorInterceptorBuilder = (axiosInstance) => {
    return {
        onBeforeRequest,
        onRequestError,
        onResponseSuccess,
        onResponseError
    };
};

// Interceptors
const onBeforeRequest = async (config) => {
    const logConfig = (({method, url, headers, data, params}) => ({method, url, headers, data, params}))(config);
    Logger.log.info("NativeInteractor Interceptor Request", logConfig);
    return config;
};

const onRequestError = (error) => {
    Logger.log.error("NativeInteractor Interceptor Request Error", error);
    throw error;
};

const onResponseSuccess = async (response) => {
    Logger.log.info("NativeInteractor Response Success", response);
    return response;
};

const onResponseError = async (error) => {
    Logger.log.error("NativeInteractor Response Error", error);
    throw error;
};