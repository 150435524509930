class UIUtils {
    static THROTTLE_TIME = 500;//milliseconds
    static lastEventTime = 0;

    static isScanEventAllowed() {
        let currentTime = new Date();
        if (currentTime - this.lastEventTime < this.THROTTLE_TIME) {
            return false;
        } else {
            this.lastEventTime = currentTime;
            return true;
        }
    }
}

export default UIUtils;