import Constants from "../constants/Constants";

export function getUAASServiceBaseURL(stage, region) {
    switch(stage) {
        case Constants.Stage.BETA:
            return Constants.DolphinURL.NA.PREPROD;
        case Constants.Stage.GAMMA:
            switch(region) {
                case Constants.Region.REGION_NA: return Constants.DolphinURL.NA.PREPROD;
                case Constants.Region.REGION_EU: return Constants.DolphinURL.EU.PREPROD;
                case Constants.Region.REGION_FE: return Constants.DolphinURL.FE.PREPROD;
                case Constants.Region.REGION_IN: return Constants.DolphinURL.EU.PREPROD;
                // no default
            }
            break;
        // no default
        case Constants.Stage.PROD:
            switch(region) {
                case Constants.Region.REGION_NA: return Constants.DolphinURL.NA.PROD;
                case Constants.Region.REGION_EU: return Constants.DolphinURL.EU.PROD;
                case Constants.Region.REGION_FE: return Constants.DolphinURL.FE.PROD;
                case Constants.Region.REGION_IN: return Constants.DolphinURL.EU.PROD;
                // no default
            }
            break;
        // no default
    }
}

export const API_ROUTES = {
    UAAS : {
        GetModulesAndFeaturesForNodeRequest :"uaas/user/modulesandfeatures/get"
    }
};