import React from "react";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import PropTypes from "prop-types";
import Button from "@amzn/meridian/button";
import {FormattedMessage} from "react-intl";
import Alert from "@amzn/meridian/alert";

const TrainingBlockerView = (props) => {
    return (
        <Column height="100%" heights="fill" spacingInset={"medium"}>
            <Column height="20%"/>
            <Alert type="warning" size="xlarge" title={<FormattedMessage id={props.title}/>}>
                <FormattedMessage id={props.description}/>
            </Alert>
            <Column width="100%" alignmentVertical="bottom">
                <Button size="large" type="primary" onClick={props.buttonOnClick}>
                    <FormattedMessage id={props.buttonText}/>
                </Button>
            </Column>
        </Column>
    );
};

TrainingBlockerView.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonOnClick: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired
};

export default TrainingBlockerView;