import MeshClient from "../client/MeshClient";
import SubscribableEvents from "../constants/SubscribableEvents";
import Logger from "../utils/Logger";
const dolphinBus = new BroadcastChannel('DOLPHIN_BUS');

const postMessagePromises = {};

// Listen for the event.
dolphinBus.addEventListener('message', function (event) {
    try {
        const eventData = JSON.parse(event.data);
        if(eventData.eventName && SubscribableEvents.SUBSCRIBABLE_EVENTS.hasOwnProperty(eventData.eventName) && postMessagePromises[eventData.eventName]) {
            postMessagePromises[eventData.eventName].resolve(eventData.payload);
        }
    } catch (JSONParseException) {
        Logger.log.warn("Enable to parse event : " + event.data);
    }
});

export function postMessagePromised(message, resolveIdentifier) {
    postMessagePromises[resolveIdentifier] = {};
    postMessagePromises[resolveIdentifier]['promise'] = new Promise(function (resolve, reject) {
        postMessagePromises[resolveIdentifier]['resolve'] = resolve;
        postMessagePromises[resolveIdentifier]['reject'] = reject;
        MeshClient.sendMessageToServer(JSON.stringify(message));
    });
    return postMessagePromises[resolveIdentifier].promise;
}