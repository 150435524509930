import React, {Component} from "react";
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom';
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import chevronLeftSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-left-small";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import {injectIntl} from "react-intl";
import {STRINGS} from "../../il8n/strings";

const BG_COLOR = {
    'default': '#252829', // grey700
    'error': '#AD0A30', // red500
    'warning': '#FEF2B8' //
};

class TopNavigationView extends Component {

    onMenuButtonClicked = () => {
        if (this.props.history) {
            this.props.history.goBack();
        }
    }

    render() {
        // preference to backgroundType if provided or fallback to backgroundColor
        const backgroundColor = BG_COLOR[this.props.backgroundType] || this.props.backgroundColor;
        return <Row backgroundColor={backgroundColor || BG_COLOR.default} width="100%" widths={["grid", "fill", "grid"]} spacingInset="small" spacing="none">
            <Button label={this.props.intl.formatMessage({id: STRINGS.menu_button})} type="icon" onClick={this.props.onMenuButtonClicked || this.onMenuButtonClicked}>
                <Text color={"inverted"}>
                    <Icon tokens={chevronLeftSmallTokens}/>
                </Text>
            </Button>
            <Text role="heading" type="h200" alignment="center" color={"inverted"} >{this.props.title}</Text>
            {
                this.props.optionsMenu.map((option, index) => {
                    return <Button label={option.label || `option ${index+1}`} key={index} type="icon" color={"inverted"} onClick={option.onClick}>
                        <Text color={"inverted"}><Icon tokens={option.icon}/></Text>
                    </Button>;
                })
            }
        </Row>;
    }
}

TopNavigationView.propTypes = {
    title: PropTypes.string.isRequired,
    onMenuButtonClicked: PropTypes.func,
    backgroundType: PropTypes.oneOf(['default', 'error']),
    backgroundColor: PropTypes.string,
    optionsMenu: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.object,
        label: PropTypes.string,
        onClick: PropTypes.func
    }))
}

export default injectIntl(withRouter(TopNavigationView));