import React, {Component} from "react";
import PropTypes from "prop-types";
import AccordionView, {AccordionViewPropTypes} from "../AccordianView";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import "./taskView.css"

class TaskView extends Component {

    createSnapshots = (tasks) => {
        return tasks.map((currentTask, index) =>
            <AccordionView
                key={index}
                {...currentTask}/>
        );
    }

    render() {
        const {pageTitle, tasks} = this.props;
        const dataSnapshots = this.createSnapshots(tasks)
        return (
            <Column height="100%" spacing="none" spacingInset="none">
                { pageTitle &&
                    <div className="left-top-medium-spacing">
                        <Text type="h200">{pageTitle}</Text>
                    </div>
                }
                <Column>
                    {dataSnapshots}
                </Column>
            </Column>
        )
    }
}

TaskView.propTypes = {
    pageTitle: PropTypes.string,
    tasks: PropTypes.arrayOf(PropTypes.shape(AccordionViewPropTypes)).isRequired
}

export default TaskView;