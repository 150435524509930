import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from "@amzn/meridian/alert";
import PropTypes from 'prop-types';
import TopNavigationView from "../TopNavigationView";
import Column from "@amzn/meridian/column";

class ErrorView extends Component {

    render() {
        const {errorMessage, title} = this.props;
        return (
            <Column>
                <TopNavigationView title={title} backgroundType={'error'} onMenuButtonClicked={this.props.onBackClicked} optionsMenu={[]}/>
                <Alert type="error" size="xlarge">
                    <FormattedMessage id={errorMessage}
                                      defaultMessage="The system has run into an issue. We are working to resolve this issue as soon as possible">
                    </FormattedMessage>
                </Alert>
            </Column>
        )
    };
}

ErrorView.propTypes = {
    errorMessage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onBackClicked: PropTypes.func
};

export default ErrorView;