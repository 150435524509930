const loadExternal = (url, callback) => {
    let element;
    if (url.endsWith(".js")) {
        element = document.createElement('script');
        element.src = url;
        element.type = 'text/javascript';
        element.async = true;
    } else if (url.endsWith(".css")) {
        element = document.createElement('link');
        element.rel = "stylesheet";
        element.href = url;
    } else {
        throw new Error("only js and css are supported");
    }
    if (callback) {
        element.addEventListener("load", () => {
            callback(true);
        });
        element.addEventListener("error", () => {
            callback(false);
        });
    }
    const headTag = document.getElementsByTagName('head')[0];
    headTag.appendChild(element);
};

export default loadExternal;