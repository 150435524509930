export const DRONA_PLAYER_LIB_REGION_BASED_URL = {
    beta: {
        NA: "https://gamma.na.player-dolphin.last-mile.amazon.dev/",
        EU: "https://gamma.na.player-dolphin.last-mile.amazon.dev/",
        FE: "https://gamma.na.player-dolphin.last-mile.amazon.dev/"
    },
    gamma: {
        NA: "https://gamma.na.player-dolphin.last-mile.amazon.dev/",
        EU: "https://gamma.eu.player-dolphin.last-mile.amazon.dev/",
        FE: "https://gamma.fe.player-dolphin.last-mile.amazon.dev/"
    },
    prod : {
        NA: "https://prod.na.player-dolphin.last-mile.amazon.dev/",
        EU: "https://prod.eu.player-dolphin.last-mile.amazon.dev/",
        FE: "https://prod.fe.player-dolphin.last-mile.amazon.dev/"
    }
}
