import React, {Component} from "react";
import PropTypes from "prop-types";
import Text from "@amzn/meridian/text";

class TextView extends Component {

    setSize = (size, type) => {
        let fontType, fontSize;
        switch (type) {
            case "Bold": fontType = "h"; break;
            case "Simple": fontType = "b"; break;
        }
        switch (size) {
            case "Small" : fontSize = "200"; break;
            case "Medium" : fontSize = "300"; break;
            case "Large" : fontSize = "500"; break;
        }
        return fontType+fontSize;
    }

    render() {
        const {title, size, type } = this.props
        const font = this.setSize(size, type)
        return (
            <Text type={font}>{title}</Text>
        )
    }
}

TextView.propTypes = {
    title: PropTypes.string.isRequired,
    size: PropTypes.string,
    type: PropTypes.string
}

TextView.defaultProps = {
    size: "Medium",
    type: "Simple"
}

export default TextView;