import React, { Component } from 'react';
import Alert from "@amzn/meridian/alert";
import PropTypes from 'prop-types';
import Column from "@amzn/meridian/column";
import {FormattedMessage} from "react-intl";

class WarningView extends Component {

    render() {
        const {warningMessage, values} = this.props;
        return (
            <Column>
                <Alert type="warning" size="xlarge">
                    <FormattedMessage id={warningMessage} values={values}
                                      defaultMessage="Warning Message">
                    </FormattedMessage>
                </Alert>
            </Column>
        )
    };
}

WarningView.propTypes = {
    warningMessage: PropTypes.string.isRequired,
    values: PropTypes.object
};

export default WarningView;
