import React, {Component} from "react";
import Column from "@amzn/meridian/column";
import PropTypes from "prop-types";
import HeaderView from "../HeaderView";
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";

/*
A grid like view to display a heading and subheading in the subgrid, with a link (optional) on it.

Attributes:

title: Title of the screen appearing at the top. It will be in bold letters with text type h300 and is a required attribute.

description: Description will appear in the b300 Text type just below the Title.

gridTitle: Title at the top of the grid

dataSnapshots: dataSnapshots will be array of details which will be displayed in each of the subgrid.

onClick: A link on which user should be redirected, if clicked on the subgrid.

dimension: To configure the number of columns in the grid .It is a required attribute.
 */

class GridView extends Component {
    onClick = (data) => {
        if(this.props.onClick) {
            this.props.onClick(data);
        }
    };
    uiItems = (dataSnapshots, dimension) => {
        let uiItems = [], temporaryRow = null, keyIndex = 0;

        for (const dataSnapshot of dataSnapshots) {
            temporaryRow = temporaryRow || [];
            temporaryRow.push(<Box key={dataSnapshot.title} type="outline" spacing="none">
                <Column spacing="none">
                    <div tabIndex="0" role="button" onClick={() => this.onClick(dataSnapshot.title)} >
                        <Text type="h200" alignment="center">{dataSnapshot.title}</Text>
                        <Text type="b200" alignment="center">{dataSnapshot.description}</Text>
                    </div>
                </Column>
            </Box>);
            if (temporaryRow.length === dimension) {
                uiItems.push(<Row key={keyIndex++} spacing={"none"} widths={Array(dimension).fill("grid-".concat((12/dimension).toString()))}>{temporaryRow}
                    </Row>);
                temporaryRow = null;
            }
        }
        // adding any trailing entries
        temporaryRow && uiItems.push(<Row key={keyIndex++} spacing={"none"} widths={Array(dimension).fill("grid-".concat((12/dimension).toString()))}>{temporaryRow}
            </Row>);
        return uiItems
    }
    render()
    {
        const {title, description, gridTitle, dataSnapshots, dimension} = this.props
        const uiItems = this.uiItems(dataSnapshots, dimension)
        const headerData = {
            primaryTitle: {
                title: title,
                type: "Bold",
                size: "Medium"
            },
            secondaryTitle: {
                title: description,
                type: "Simple",
                size: "Medium"
            }
        }
        return (
            <Column>
                <Column>
                    <HeaderView {...headerData}/>
                    <Column spacing="none">
                        <Box width="100%" type="outline" spacing="none">
                            <Column backgroundColor={"#2B3648"}>
                                <Text type="h300" alignment="center" color="inverted">
                                    {gridTitle}
                                </Text>
                             </Column>
                            {uiItems}
                        </Box>
                    </Column>
                </Column>
            </Column>
        )
    }
}

GridView.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    gridTitle: PropTypes.string.isRequired,
    dataSnapshots: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string
    })).isRequired,
    dimension: PropTypes.number.isRequired,
    onClick: PropTypes.func
}

export default GridView;