import { v4 as uuidv4 } from 'uuid';
import Logger from "../utils/Logger";

export default {
    sessionId : "",
    generateSessionId() {
        this.sessionId = uuidv4();
        Logger.log.info("Generating UUID for session " + this.sessionId);
    },
    getSessionId() {
        return this.sessionId;
    }
}