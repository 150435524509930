import DataHandler from "../handler/DataHandler";
import Logger from "../utils/Logger";
import {createEMFObject} from "../utils/Logger";

export default {
    moduleName : null,
    pumaInit(country, region, stage, appId, moduleName){
        this.moduleName = moduleName;
    },
    publishLatencyToDolphinCSM(startTime, metricName) {
        try {
            const namespace = "KatalLogger";
            const metricNameLabel = "Latency";
            const metricValue = getTimeDiff(startTime);
            const metricUnit = "Milliseconds";
            const latencyMetric = metricName + "-Latency";
            
            // Use KatalLogger Lambda to publish CloudWatch EMF logs
            // Metric 1: Latency metric with dimension of API name 
            // so we can monitor latency across all stations
            Logger.log.warn(latencyMetric, {
                emfLog: createEMFObject(
                    namespace,
                    metricNameLabel,
                    metricValue,
                    metricUnit,
                    {'ApiName': metricName}
                    )
                })
            // Metric 2: Latency metric with dimensions of API name + station code 
            // so we can monitor latency for each station
            Logger.log.warn(latencyMetric, {
                emfLog: createEMFObject(
                    namespace,
                    metricNameLabel,
                    metricValue,
                    metricUnit,
                    {'ApiName': metricName, 'StationCode': DataHandler.getStationCode()}
                    )
                })
        } catch (e) {
            Logger.log.warn(`Exception occurred for KatalLogger publishing Latency EMF logs. Exception: ${e}`);
        }
    },
    publishCountToDolphinCSM(metricName) {
        try {
            const namespace = "KatalLogger";
            const metricValue = 1;
            const metricUnit = "Count";

            // Use KatalLogger Lambda to publish CloudWatch EMF logs
            // Metric 1: Error/Failure metric with dimension of Metric name
            // so we can monitor across all stations
            Logger.log.warn(metricName, {
                emfLog: createEMFObject(
                    namespace,
                    metricName,
                    metricValue,
                    metricUnit,
                    {'ApiName': metricName}
                )
            });

            // Metric 2: Error/Failure metric with dimensions of Metric name + station code
            // so we can monitor for each station
            Logger.log.warn(metricName, {
                emfLog: createEMFObject(
                    namespace,
                    metricName,
                    metricValue,
                    metricUnit,
                    {'ApiName': metricName, 'StationCode': DataHandler.getStationCode()}
                )
            });

        } catch (e) {
            Logger.log.warn(`Exception occurred for KatalLogger publishing Error/Failure EMF logs. Exception: ${e}`);
        }
    }
}

function getTimeDiff(time) {
    return Date.now() - time ;
}