import NativeMeshInteractor from "./NativeMeshInteractor";
import DataHandler from "../handler/DataHandler";

export default {
    accessToken: "",
    async setAccessToken() {
        //Fallback case - Prevent access token calls to native if customerId is available
        if (!DataHandler.getUserCustomerId()) {
            this.accessToken = await NativeMeshInteractor.getAccessToken();
        }
    }
};