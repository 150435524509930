import UAParser from "ua-parser-js";

const ua = new UAParser();
export default {
    /**
     * Get device IMEI
     * To be replaced by device serial number in future
     * @returns {string}
     */
    getDeviceIMEI() {
        return "00000";
    },

    /**
     * Get device chrome version
     * @returns {string}
     */
    getChromeVersion() {
        return (ua.getBrowser())?ua.getBrowser().major:"";
    },

    /**
     * Get device OS version
     * @returns {string}
     */
    getDeviceOSVersion() {
        return (ua.getOS())?ua.getOS().version:"";
    },

    /**
     * Get device manufacturer
     * @returns {string}
     */
    getDeviceManufacturer() {
        return (ua.getDevice())?ua.getDevice().vendor:"";
    },

    /**
     * Get device model name
     * @returns {string}
     */
    getDeviceModelName() {
        return (ua.getDevice())?ua.getDevice().model:"";
    },

    /**
     * Get device network connectivity status
     * Always true in our case
     * @returns {string}
     */
    getDeviceNetworkType() {
        return "true";
    },

    /**
     * Get PWA build version
     * @returns {string}
     */
    getBuildVersion() {
        return "1.0.0";
    }
}