export const getContainerId = (data) => {
    try {
        const parsedData = JSON.parse(data);
        if (parsedData && parsedData.cids && parsedData.cids.pkey) {
            return parsedData.cids.pkey;
        }
    } catch (e) {
        return data;
    }
    return data;
};