import React from "react";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import MeridianLoader from "@amzn/meridian/loader";
import "./loader.css"

export default function Loader() {
    return (
        <Column className="loader" height="100%" width="100%" heights="fill" alignmentHorizontal="center">
            <Row widths="fill">
                <MeridianLoader type="circular" size="medium"/>
            </Row>
        </Column>
    );
}